// override booking calender function
window.makeScroll = (object_name) => {
  let targetOffset = jQuery(object_name).offset().top;
  const $navbar = jQuery('.navbar');
  if (targetOffset <= 0) {
    if (0 != jQuery(object_name).nextAll(':visible').length) {
      targetOffset = jQuery(object_name)
        .nextAll(':visible')
        .first()
        .offset().top; //FixIn: 9.2.3.9
    } else if (0 != jQuery(object_name).parent().nextAll(':visible').length) {
      targetOffset = jQuery(object_name)
        .parent()
        .nextAll(':visible')
        .first()
        .offset().top;
    }
  }

  if (jQuery('#wpadminbar').length > 0) {
    targetOffset = targetOffset - 50;
  } else {
    targetOffset = targetOffset - 20;
  }

  if ($navbar.length) {
    targetOffset -= $navbar.height() + jQuery(window).height() / 5;
  }

  jQuery('html,body').stop().animate({ scrollTop: targetOffset }, 500);
};

// Show Error Message in Booking Form  at Front End
window.showErrorMessage = (element, errorMessage, isScrollStop) => {
  //FixIn: 8.5.1.3
  //FixIn: 8.4.0.2
  // if(typeof( bk_form_step_click ) == 'function') {
  //     bk_form_step_click();                                                   // rollback  to 1st  step,  if system  will show warning and booking form  is using this customization: in the Exmaple #2 here: https://wpbookingcalendar.com/faq/customize-booking-form-for-having-several-steps-of-reservation/
  // }

  //FixIn: 8.5.1.3
  //   if (!isScrollStop) {
  //     makeScroll(element);
  //   }
  //FixIn: 8.7.11.10
  //   if (jQuery("[name='" + element.name + "']").is(':visible')) {
  //     jQuery("[name='" + element.name + "']")
  //       .fadeIn(400)
  //       .animate({ opacity: 1 }, 4000); // mark red border
  //   }
  if (jQuery("[name='" + element.name + "']").attr('type') == 'radio') {
    if (
      !jQuery("[name='" + element.name + "']")
        .parent()
        .parent()
        .parent()
        .next()
        .hasClass('alert-warning') ||
      (jQuery("[name='" + element.name + "']")
        .parent()
        .parent()
        .parent()
        .next()
        .hasClass('alert-warning') &&
        !jQuery("[name='" + element.name + "']")
          .parent()
          .parent()
          .parent()
          .next()
          .is(':visible'))
    ) {
      //FixIn: 8.4.5.7
      jQuery("[name='" + element.name + "']")
        .parent()
        .parent()
        .parent()
        .after(
          '<span class="wpdev-help-message alert alert-warning">' +
            errorMessage +
            '</span>'
        ); // Show message
    }
  } else if (
    jQuery("[name='" + element.name + "']").attr('type') == 'checkbox'
  ) {
    if (
      !jQuery("[name='" + element.name + "']")
        .parent()
        .next()
        .hasClass('alert-warning') ||
      (jQuery("[name='" + element.name + "']")
        .parent()
        .next()
        .hasClass('alert-warning') &&
        !jQuery("[name='" + element.name + "']")
          .parent()
          .next()
          .is(':visible'))
    ) {
      //FixIn: 8.4.5.7
      jQuery("[name='" + element.name + "']")
        .parent()
        .after(
          '<span class="wpdev-help-message alert alert-warning">' +
            errorMessage +
            '</span>'
        ); // Show message
    }
  } else {
    if (
      !jQuery("[name='" + element.name + "']")
        .next()
        .hasClass('alert-warning') ||
      (jQuery("[name='" + element.name + "']")
        .next()
        .hasClass('alert-warning') &&
        !jQuery("[name='" + element.name + "']")
          .next()
          .is(':visible'))
    ) {
      //FixIn: 8.4.5.7
      jQuery("[name='" + element.name + "']").after(
        '<span class="wpdev-help-message alert alert-warning">' +
          errorMessage +
          '</span>'
      ); // Show message
    }
  }

  jQuery('.wpdev-help-message').css({
    // 'padding'       : '5px 5px 4px',
    margin: '2px 2px 2px 10px',
    'vertical-align': 'top',
    // 'line-height'   : '32px'
  });
  // Calendar error message:          //FixIn: 9.3.1.9
  jQuery('.wpdev-element-message').css({
    margin: '10px 5px 10px 0px',
    'line-height': '32px',
  });

  if (element.type == 'checkbox')
    jQuery('.wpdev-help-message').css({ 'vertical-align': 'middle' });

  jQuery('.widget_wpdev_booking .booking_form .wpdev-help-message').css({
    'vertical-align': 'sub',
  });
  //   jQuery('.wpdev-help-message').animate({ opacity: 1 }, 10000).fadeOut(2000);

  if (!jQuery("[name='" + element.name + "']").is(':visible')) {
    makeScroll(jQuery('.wpdev-help-message'));
  }
  jQuery(element).trigger('focus'); //FixIn: 8.7.11.12
  return element; //FixIn: 9.3.1.9
};

//FixIn: 8.4.0.2
/**
 * Check errors in booking form  fields, and show warnings if some errors exist.
 * Check  errors,  like not selected dates or not filled requred form  fields, or not correct entering email or phone fields,  etc...
 *
 * @param bk_type  int (ID of booking resource)
 */
window.wpbc_check_errors_in_booking_form = (bk_type) => {
  var has_error_in_field = false; // By default all  is good - no error
  var my_form = jQuery('#booking_form' + bk_type);

  if (my_form.length) {
    var fields_with_errors_arr = [];

    // Pseudo-selector that get form elements <input , <textarea , <select, <button...
    my_form.find(':input').each(function (index, el) {
      var is_error_in_field = false;
      // Skip some elements
      // debugger;
      var skip_elements = ['hidden', 'button'];

      const $el = jQuery(el);
      if (['text', 'number', 'email'].includes($el.attr('type'))) {
        jQuery(el).parent().find('.wpdev-help-message').remove();
      }

      if (-1 == skip_elements.indexOf(jQuery(el).attr('type'))) {
        // Check Calendar Dates Selection
        if ('date_booking' + bk_type == jQuery(el).attr('name')) {
          // Show Warning only  if the calendar visible ( we are at step with  calendar)
          if (
            jQuery('#calendar_booking' + bk_type).is(':visible') &&
            '' == jQuery(el).val() &&
            wpbc_get_arr_of_selected_additional_calendars(bk_type).length == 0 //FixIn: 8.5.2.26
          ) {
            //FixIn: 8.4.4.5
            showMessageUnderElement(
              '#date_booking' + bk_type,
              message_verif_selectdts,
              ''
            );
            makeScroll('#calendar_booking' + bk_type); // Scroll to the calendar    		//FixIn: 8.5.1.3
            is_error_in_field = true; // Error
          }
        }

        // Check only visible elements at this step
        if (jQuery(el).is(':visible')) {
          // console.log( '|id, type, val, visible|::', jQuery( el ).attr( 'name' ), '|' + jQuery( el ).attr( 'type' ) + '|', jQuery( el ).val(), jQuery( el ).is( ':visible' ) );

          // Is Required
          if (jQuery(el).hasClass('wpdev-validates-as-required')) {
            // Checkboxes
            if ('checkbox' == jQuery(el).attr('type')) {
              if (
                !jQuery(el).is(':checked') &&
                !jQuery(':checkbox[name="' + el.name + '"]', my_form).is(
                  ':checked'
                ) //FixIn: 8.5.2.12
              ) {
                window.showErrorMessage(
                  el,
                  message_verif_requred_for_check_box,
                  is_error_in_field
                );
                fields_with_errors_arr.push(el);
                is_error_in_field = true; // Error
              }

              // Radio boxes
            } else if ('radio' == jQuery(el).attr('type')) {
              if (
                !jQuery(
                  ':radio[name="' + jQuery(el).attr('name') + '"]',
                  my_form
                ).is(':checked')
              ) {
                window.showErrorMessage(
                  el,
                  message_verif_requred_for_radio_box,
                  is_error_in_field
                );
                fields_with_errors_arr.push(el);
                is_error_in_field = true; // Error
              }

              // Other elements
            } else {
              var inp_value = jQuery(el).val();

              if ('' === wpbc_trim(inp_value)) {
                //FixIn: 8.8.1.3        //FixIn: 8.7.11.12
                window.showErrorMessage(
                  el,
                  message_verif_requred,
                  is_error_in_field
                );
                fields_with_errors_arr.push(el);
                is_error_in_field = true; // Error
              }
            }
          }

          // Validate Email
          if (jQuery(el).hasClass('wpdev-validates-as-email')) {
            var inp_value = jQuery(el).val();
            inp_value = inp_value.replace(/^\s+|\s+$/gm, ''); // Trim  white space //FixIn: 5.4.5
            var reg =
              /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/;
            if (inp_value != '' && reg.test(inp_value) == false) {
              window.showErrorMessage(
                el,
                message_verif_emeil,
                is_error_in_field
              );
              fields_with_errors_arr.push(el);
              is_error_in_field = true; // Error
            }
          }

          // Validate For digit entering - for example for - Phone
          // <p>Digit Field:<br />[text* dig_field class:validate_as_digit] </p>
          // <p>Phone:<br />[text* phone class:validate_digit_8] </p>

          var classList = jQuery(el).attr('class');

          if (classList) {
            classList = classList.split(/\s+/);

            jQuery.each(classList, function (cl_index, cl_item) {
              ////////////////////////////////////////////////////////////////////////////////////////////

              // Validate field value as "Date"   [CSS class - 'validate_as_digit']
              if ('validate_as_date' === cl_item) {
                // Valid values: 09-25-2018, 09/25/2018, 09-25-2018,  31-9-1918  ---   m/d/Y, m.d.Y, m-d-Y, d/m/Y, d.m.Y, d-m-Y
                var regex = new RegExp(
                  '^[0-3]?\\d{1}[\\/\\.\\-]+[0-3]?\\d{1}[\\/\\.\\-]+[0-2]+\\d{3}$'
                ); // Check for Date 09/25/2018
                var message_verif_phone =
                  'This field must be valid date like this ' + '09/25/2018';
                var inp_value = jQuery(el).val();

                if (inp_value != '' && regex.test(inp_value) == false) {
                  window.showErrorMessage(
                    el,
                    message_verif_phone,
                    is_error_in_field
                  );
                  fields_with_errors_arr.push(el);
                  is_error_in_field = true; // Error
                }
              }

              ////////////////////////////////////////////////////////////////////////////////////////////

              // Validate field value as "DIGIT"   [CSS class - 'validate_as_digit']
              if ('validate_as_digit' === cl_item) {
                var regex = new RegExp('^[0-9]+\\.?[0-9]*$'); // Check for digits
                var message_verif_phone = 'This field must contain only digits';
                var inp_value = jQuery(el).val();

                if (inp_value != '' && regex.test(inp_value) == false) {
                  window.showErrorMessage(
                    el,
                    message_verif_phone,
                    is_error_in_field
                  );
                  fields_with_errors_arr.push(el);
                  is_error_in_field = true; // Error
                }
              }

              ////////////////////////////////////////////////////////////////////////////////////////////

              // Validate field value as "Phone" number or any other valid number wth specific number of digits [CSS class - 'validate_digit_8' || 'validate_digit_10' ]
              var is_validate_digit = cl_item.substring(0, 15);

              // Check  if class start  with 'validate_digit_'
              if ('validate_digit_' === is_validate_digit) {
                // Get  number of digit in class: validate_digit_8 => 8 or validate_digit_10 => 10
                var digits_to_check = parseInt(cl_item.substring(15));

                // Check  about any errors in
                if (!isNaN(digits_to_check)) {
                  var regex = new RegExp('^\\d{' + digits_to_check + '}$'); // We was valid it as parseInt - only integer variable - digits_to_check
                  var message_verif_phone =
                    'This field must contain ' + digits_to_check + ' digits';
                  var inp_value = jQuery(el).val();

                  if (inp_value != '' && regex.test(inp_value) == false) {
                    window.showErrorMessage(
                      el,
                      message_verif_phone,
                      is_error_in_field
                    );
                    fields_with_errors_arr.push(el);
                    is_error_in_field = true; // Error
                  }
                }
              }

              ////////////////////////////////////////////////////////////////////////////////////////////
            });
          }
        }
      }
      console.log('EL', is_error_in_field, fields_with_errors_arr, el);
      if (is_error_in_field) {
        has_error_in_field = true;
      }
    });

    if (fields_with_errors_arr.length > 0) {
      jQuery(fields_with_errors_arr[0]).trigger('focus'); //FixIn: 9.3.1.9
    }
  }

  return has_error_in_field;
};

window.mybooking_submit = (submit_form, bk_type, wpdev_active_locale) => {
  //console.log( wpbc_get_arr_of_selected_additional_calendars( bk_type ) );

  var target_elm = jQuery('.booking_form_div').trigger(
    'booking_form_submit_click',
    [bk_type, submit_form, wpdev_active_locale]
  ); //FixIn: 8.8.3.13
  if (
    jQuery(target_elm).find('input[name="booking_form_show_summary"]').length >
      0 &&
    'pause_submit' ===
      jQuery(target_elm).find('input[name="booking_form_show_summary"]').val()
  ) {
    return false;
  }

  //FixIn: 8.4.0.2
  var is_error = window.wpbc_check_errors_in_booking_form(bk_type);
  if (is_error) {
    return false;
  }

  // Show message if no selected days in Calendar(s)
  if (document.getElementById('date_booking' + bk_type).value == '') {
    var arr_of_selected_additional_calendars =
      wpbc_get_arr_of_selected_additional_calendars(bk_type); //FixIn: 8.5.2.26

    if (arr_of_selected_additional_calendars.length == 0) {
      showMessageUnderElement(
        '#date_booking' + bk_type,
        message_verif_selectdts,
        ''
      );
      makeScroll('#calendar_booking' + bk_type); // Scroll to the calendar
      return;
    }
  }

  var count = submit_form.elements.length;
  var formdata = '';
  var inp_value;
  var element;
  var el_type;

  // Serialize form here
  for (var i = 0; i < count; i++) {
    //FixIn: 9.1.5.1
    element = submit_form.elements[i];

    if (jQuery(element).closest('.booking_form_garbage').length) {
      continue; // Skip elements from garbage                                           //FixIn: 7.1.2.14
    }

    if (
      element.type !== 'button' &&
      element.type !== 'hidden' &&
      element.name !== 'date_booking' + bk_type
      // && ( jQuery( element ).is( ':visible' ) )                                            //FixIn: 7.2.1.12.2 // Its prevent of saving hints,  and some other hidden element
    ) {
      // Skip buttons and hidden element - type                                     //FixIn: 7.2.1.12

      // Get Element Value
      if (element.type == 'checkbox') {
        if (element.value == '') {
          inp_value = element.checked;
        } else {
          if (element.checked) inp_value = element.value;
          else inp_value = '';
        }
      } else if (element.type == 'radio') {
        if (element.checked) {
          inp_value = element.value;
        } else {
          // Check  if this radio required,  and if it does not check,  then show warning, otherwise if it is not required or some other option checked skip this loop
          // We need to  check  it here, because radio have the several  options with  same name and type, and otherwise we will save several options with  selected and empty values.
          if (
            //FixIn: 7.0.1.62
            element.className.indexOf('wpdev-validates-as-required') !== -1 &&
            jQuery(element).is(':visible') && //FixIn: 7.2.1.12.2 // Its prevent of saving hints,  and some other hidden element
            !jQuery(':radio[name="' + element.name + '"]', submit_form).is(
              ':checked'
            )
          ) {
            window.showErrorMessage(
              element,
              message_verif_requred_for_radio_box,
              false
            ); //FixIn: 8.5.1.3
            return;
          }
          continue;
        }
      } else {
        inp_value = element.value;
      }

      // Get value in selectbox of multiple selection
      if (element.type == 'select-multiple') {
        inp_value = jQuery('[name="' + element.name + '"]').val();
        if (inp_value == null || inp_value.toString() == '') inp_value = '';
      }

      // Make validation  only  for visible elements
      if (jQuery(element).is(':visible')) {
        //FixIn: 7.2.1.12.2
        // Recheck for max num. available visitors selection
        if (element.name == 'visitors' + bk_type) {
          if (
            typeof is_max_visitors_selection_more_than_available == 'function'
          ) {
            //FixIn: 8.2.1.28
            if (
              wpbc_is_time_field_in_booking_form(
                bk_type,
                submit_form.elements
              ) &&
              !wpdev_in_array(parent_booking_resources, bk_type)
            ) {
              // We are having timeslots and this is single booking resource,  so  skip checking for max number of visitors
            } else {
              if (
                is_max_visitors_selection_more_than_available(
                  bk_type,
                  inp_value,
                  element
                )
              ) {
                return;
              }
            }
          }
        }
        // Phone validation
        /*if ( element.name == ('phone'+bk_type) ) {
                        // we validate a phone number of 10 digits with no comma, no spaces, no punctuation and there will be no + sign in front the number - See more at: http://www.w3resource.com/javascript/form/phone-no-validation.php#sthash.U9FHwcdW.dpuf
                        var reg =  /^\d{10}$/;
                        var message_verif_phone = "Please enter correctly phone number";
                        if ( inp_value != '' )
                            if(reg.test(inp_value) == false) {window.showErrorMessage( element , message_verif_phone, false );return;}
                    }*/

        // Validation Check --- Requred fields
        if (element.className.indexOf('wpdev-validates-as-required') !== -1) {
          if (element.type == 'checkbox' && element.checked === false) {
            if (
              !jQuery(':checkbox[name="' + element.name + '"]', submit_form).is(
                ':checked'
              )
            ) {
              window.showErrorMessage(
                element,
                message_verif_requred_for_check_box,
                false
              ); //FixIn: 8.5.1.3
              return;
            }
          }
          if (element.type == 'radio') {
            if (
              !jQuery(':radio[name="' + element.name + '"]', submit_form).is(
                ':checked'
              )
            ) {
              window.showErrorMessage(
                element,
                message_verif_requred_for_radio_box,
                false
              ); //FixIn: 8.5.1.3
              return;
            }
          }

          if (
            element.type != 'checkbox' &&
            element.type != 'radio' &&
            '' === wpbc_trim(inp_value)
          ) {
            //FixIn: 8.8.1.3   //FixIn:7.0.1.39       //FixIn: 8.7.11.12
            window.showErrorMessage(element, message_verif_requred, false); //FixIn: 8.5.1.3
            return;
          }
        }

        // Validation Check --- Email correct filling field
        if (element.className.indexOf('wpdev-validates-as-email') !== -1) {
          inp_value = inp_value.replace(/^\s+|\s+$/gm, ''); // Trim  white space //FixIn: 5.4.5
          var reg =
            /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/;
          if (inp_value != '')
            if (reg.test(inp_value) == false) {
              window.showErrorMessage(element, message_verif_emeil, false); //FixIn: 8.5.1.3
              return;
            }
        }

        // Validation Check --- Same Email Field
        if (
          element.className.indexOf('wpdev-validates-as-email') !== -1 &&
          element.className.indexOf('same_as_') !== -1
        ) {
          // Get  the name of Primary Email field from the "same_as_NAME" class
          var primary_email_name =
            element.className.match(/same_as_([^\s])+/gi);
          if (primary_email_name != null) {
            // We found
            primary_email_name = primary_email_name[0].substr(8);

            // Recehck if such primary email field exist in the booking form
            if (
              jQuery('[name="' + primary_email_name + bk_type + '"]').length > 0
            ) {
              // Recheck the values of the both emails, if they do  not equla show warning
              if (
                jQuery(
                  '[name="' + primary_email_name + bk_type + '"]'
                ).val() !== inp_value
              ) {
                window.showErrorMessage(
                  element,
                  message_verif_same_emeil,
                  false
                ); //FixIn: 8.5.1.3
                return;
              }
            }
          }
          // Skip one loop for the email veryfication field
          continue; //FixIn: 8.1.2.15
        }
      }

      // Get Form Data
      if (element.name !== 'captcha_input' + bk_type) {
        if (formdata !== '') formdata += '~'; // next field element

        el_type = element.type;
        if (element.className.indexOf('wpdev-validates-as-email') !== -1)
          el_type = 'email';
        if (element.className.indexOf('wpdev-validates-as-coupon') !== -1)
          el_type = 'coupon';

        inp_value = inp_value + '';
        inp_value = inp_value.replace(new RegExp('\\^', 'g'), '&#94;'); // replace registered characters
        inp_value = inp_value.replace(new RegExp('~', 'g'), '&#126;'); // replace registered characters

        inp_value = inp_value.replace(/"/g, '&#34;'); // replace double quot
        inp_value = inp_value.replace(/'/g, '&#39;'); // replace single quot

        formdata += el_type + '^' + element.name + '^' + inp_value; // element attr
      }
    }
  } // End Fields Loop

  //FixIn:6.1.1.3
  if (typeof wpbc_is_this_time_selection_not_available == 'function') {
    if (document.getElementById('date_booking' + bk_type).value == '') {
      // Primary calendar not selected.

      if (document.getElementById('additional_calendars' + bk_type) != null) {
        // Checking additional calendars.

        var id_additional_str = document.getElementById(
          'additional_calendars' + bk_type
        ).value; //Loop have to be here based on , sign
        var id_additional_arr = id_additional_str.split(',');
        var is_times_dates_ok = false;
        for (var ia = 0; ia < id_additional_arr.length; ia++) {
          if (
            document.getElementById('date_booking' + id_additional_arr[ia])
              .value != '' &&
            !wpbc_is_this_time_selection_not_available(
              id_additional_arr[ia],
              submit_form.elements
            )
          ) {
            is_times_dates_ok = true;
          }
        }
        if (!is_times_dates_ok) return;
      }
    } else {
      //Primary calendar selected.
      if (
        wpbc_is_this_time_selection_not_available(bk_type, submit_form.elements)
      )
        return;
    }
  }

  if (bk_days_selection_mode == 'dynamic') {
    // Check if visitor finish  dates selection.
    //FixIn:6.1.1.5
    var selected_dates_cal_id = []; // Get ID of calendars,  where selected dates.
    if (document.getElementById('date_booking' + bk_type).value != '') {
      selected_dates_cal_id[selected_dates_cal_id.length] = bk_type;
    }
    if (document.getElementById('additional_calendars' + bk_type) != null) {
      // Checking according additional calendars.
      var id_additional_str = document.getElementById(
        'additional_calendars' + bk_type
      ).value; //Loop have to be here based on , sign
      var id_additional_arr = id_additional_str.split(',');
      var is_all_additional_days_unselected = true;
      for (var ia = 0; ia < id_additional_arr.length; ia++) {
        if (
          document.getElementById('date_booking' + id_additional_arr[ia])
            .value != ''
        ) {
          selected_dates_cal_id[selected_dates_cal_id.length] =
            id_additional_arr[ia];
        }
      }
    }
    for (var ci = 0; ci < selected_dates_cal_id.length; ci++) {
      var abk_type = selected_dates_cal_id[selected_dates_cal_id.length];
      if (document.getElementById('calendar_booking' + abk_type) != null) {
        var inst = jQuery.datepick._getInst(
          document.getElementById('calendar_booking' + abk_type)
        );
        if (bk_2clicks_mode_days_min != undefined) {
          if (
            typeof check_conditions_for_range_days_selection_for_check_in ==
            'function'
          ) {
            var first_date = get_first_day_of_selection(
              document.getElementById('date_booking' + abk_type).value
            );
            var date_sections = first_date.split('.');
            var selceted_first_day = new Date();
            selceted_first_day.setFullYear(
              parseInt(date_sections[2] - 0),
              parseInt(date_sections[1] - 1),
              parseInt(date_sections[0] - 0)
            );
            check_conditions_for_range_days_selection_for_check_in(
              selceted_first_day,
              abk_type
            );
          }
          if (inst.dates.length < bk_2clicks_mode_days_min) {
            showMessageUnderElement(
              '#date_booking' + abk_type,
              message_verif_selectdts,
              ''
            );
            makeScroll('#calendar_booking' + abk_type); // Scroll to the calendar
            return;
          }
        }
      }
    }
  }
  window.mobileOpticaOptions.form = {
    bk_type,
    formdata,
    wpdev_active_locale,
  };
  jQuery('#acceptModal').modal('toggle');
};

window.send_ajax_submit = (
  bk_type,
  formdata,
  captcha_chalange,
  user_captcha,
  is_send_emeils,
  my_booking_hash,
  my_booking_form,
  wpdev_active_locale
) => {
  // Ajax POST here

  var my_bk_res = bk_type;
  if (document.getElementById('bk_type' + bk_type) != null)
    my_bk_res = document.getElementById('bk_type' + bk_type).value;

  jQuery.ajax({
    // Start Ajax Sending
    // url: wpdev_bk_plugin_url+ '/' + wpdev_bk_plugin_filename,
    url: wpbc_ajaxurl,
    type: 'POST',
    success: function (data, textStatus) {
      if (textStatus == 'success') {
        jQuery('#ajax_respond_insert' + bk_type).html(data);
        jQuery('.booking-container').trigger('booked');
      }
    },
    error: function (XMLHttpRequest, textStatus, errorThrown) {
      window.status = 'Ajax sending Error status:' + textStatus;
      alert(XMLHttpRequest.status + ' ' + XMLHttpRequest.statusText);
      if (XMLHttpRequest.status == 500) {
        alert(
          'Please check at this page according this error:' +
            ' https://wpbookingcalendar.com/faq/#ajax-sending-error'
        );
      }
    },
    // beforeSend: someFunction,
    data: {
      // ajax_action : 'INSERT_INTO_TABLE',
      action: 'INSERT_INTO_TABLE',
      bktype: my_bk_res,
      dates: document.getElementById('date_booking' + bk_type).value,
      form: formdata,
      captcha_chalange: captcha_chalange,
      captcha_user_input: user_captcha,
      is_send_emeils: is_send_emeils,
      my_booking_hash: my_booking_hash,
      booking_form_type: my_booking_form,
      wpdev_active_locale: wpdev_active_locale,
      wpbc_nonce: document.getElementById('wpbc_nonce' + bk_type).value,
    },
  });
};
